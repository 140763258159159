import React from "react"
import MassageCards from "../components/MassageCards"
import Container from "react-bootstrap/Container"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/Seo"

const H4 = styled.h4`
  font-family: "Josefin Sans", sans-serif;
`
const H1 = styled.h1`
  font-size: 2rem;
`

const HR = styled.hr`
  border: 3px solid #8db596;
  border-radius: 5px;
  width: 50%;
  margin-bottom: 2rem;
`

const Classes = props => {
  return (
    <Layout>
      <SEO
        title="Massage & Bodywork Valencia"
        description="Thai yoga massage is an ancient Asian technique to relieve tension and
          enhance flexibility and range of motion.
          Fascial release massage treating skeletal muscle immobility and pain
          by relaxing contracted muscles, improving blood and lymphatic
          circulation, and stimulating the stretch reflex in muscles."
      />
      <Container>
        <H1 className="text-center mt-4">Massages</H1>
        <H4 className="text-center mb-4">
          Thai massage | Aromatherapy massage | Myofascial release
        </H4>
        <HR />
        <MassageCards />
      </Container>
    </Layout>
  )
}

export default Classes
