import React from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import texture1 from "../assets/designs/black-thread-light.png"
import { Link } from "gatsby"

const StyledCard = styled(Card)`
  margin: 0rem 1rem 2rem !important;
  background: #fcf7ed;
  background-image: url(${texture1});
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.15) !important;
  height: auto;

  @media (max-width: 1199px) {
    height: auto;
  }
  @media (max-width: 991px) {
    margin: 0rem 0rem 1rem !important;
    height: auto;
  }
  &:hover {
    background: #fcf7ed;
    background-image: url(${texture1});
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.25) !important;
  }
  .card-body {
    text-align: center;
    padding: 1.5rem 1.5rem 0 !important;
  }
  .card-title {
    font-size: 1.5rem;
    font-family: "Josefin Sans", sans-serif;
  }
  .card-text {
    text-align: justify;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`
const H4 = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`
const H5 = styled.h4`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
`
const StyledButton = styled(Button)`
  background-color: #7c4162;
  border: 0;
  &:hover {
    background-color: #5e2b48;
  }
`
const MassageCards = () => {
  return (
    <div>
      <H4>Special offer for September</H4>
      <H5>60 min - 45 euros | 90 min - 65 euros</H5>
      <StyledCard>
        <Card.Body>
          <Card.Title>Thai yoga massage on table</Card.Title>
          <Card.Text>
            This massage is performed while you are fully clothed, please wear
            computable and light clothes.
            <br />
            No oil is used during this massage. Thai yoga massage focuses
            stretching, pulling, and rocking techniques to relieve tension and
            enhance flexibility and range of motion.
            <br />
            I use my hands, knees, legs, and feet to move you onto a series of
            yoga-like stretches and also apply deep muscle compressing, joint
            mobilization and acupressure.
            <br />
            Thai yoga massage is always practiced in a meditative mood. It is
            said to be "the physical application of loving kindness". <br />
            Thai massages utilize energy work, which according to ancient Asian
            culture, treats the subtle energetic field within the body. It
            corrects blockages, deficiencies, and imbalances in the flow of this
            energy, which improves a healthy life in general.
          </Card.Text>
          <Link to="/contact/#contact">
            <StyledButton
              className="mb-4 btn-lg w-35 btn-style"
              variant="info"
              type="submit"
            >
              Book a massage
            </StyledButton>
          </Link>
        </Card.Body>
      </StyledCard>
      <StyledCard>
        <Card.Body>
          <Card.Title>Aromatherapy oil massage</Card.Title>
          <Card.Text>
            If you choose the traditional Thai Oil massage you have 2 options -
            one is more relaxing, and claim pressure or more strong energizing
            deep pressure. <br /> Both options will have the benefits of release
            tension, pain management, improved mood and blood circulations.{" "}
            <br />
            I use Grapeseed oil, It's relatively high in vitamin E and phenolic
            antioxidants, as well as a rich source of omega-6 fatty acids. I
            combine a few drops of essential oil for you to choose.
            <br /> It is recommended to have a shower before the massage, and
            not after for not losing the benefits of the therapeutics detox and
            the value of the oils.
          </Card.Text>
          <Link to="/contact/#contact">
            <StyledButton
              className="mb-4 btn-lg w-35 btn-style"
              variant="info"
              type="submit"
            >
              Book a massage
            </StyledButton>
          </Link>
        </Card.Body>
      </StyledCard>
      <StyledCard>
        <Card.Body>
          <Card.Title>Myofascial release therapy</Card.Title>
          <Card.Text>
            Fascial release massage treating skeletal muscle immobility and pain
            by relaxing contracted muscles, improving blood and lymphatic
            circulation, and stimulating the stretch reflex in muscles.
            <br /> <br />
            <em>
              “Fascia is the biological fabric that holds us together, the
              connective tissue network. You are about 70 trillion cells –
              neurons, muscle cells, epithelia – all humming in relative
              harmony; fascia is the 3-D spider web of fibrous, gluey, and wet
              proteins that binds them all together in their proper placement.“
            </em>{" "}
            - Tom Myers
            <br /> <br />
            In this massage I use Tom Myers 12 ‘myofascial meridians’ in the
            Anatomy Trains system. First time clients get a 15 min body reading
            assessment on posture and muscles function. After the assessment we
            start our massage according to your body needs., at the end of our
            sessions you can see and feel how balanced you are from left and
            right, front and back. If you never tried this method before, I
            really recommend it.
            <br /> <br />
            <em>
              {" "}
              “No muscle / no cell / no molecule is separated from the other,
              all is united by this incredibly intelligent network of proteins &
              liquids & fibers to create the human being that is you“
            </em>{" "}
            - Tom Myers
          </Card.Text>
          <Link to="/contact/#contact">
            <StyledButton
              className="mb-4 btn-lg w-35 btn-style"
              variant="info"
              type="submit"
            >
              Book a massage
            </StyledButton>
          </Link>
        </Card.Body>
      </StyledCard>
    </div>
  )
}

export default MassageCards
